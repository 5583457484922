import { Text } from 'fitify-ui'
import { DataTypes, PageTypes } from 'fitify-ui-landing/src/@types'
import { ELEMENT_IDS } from 'fitify-ui-landing/src/@types/constants'
import { ContentRevealAnimation } from 'fitify-ui-landing/src/components/animations/ContentRevealAnimation'
import Container from 'fitify-ui-landing/src/components/Container/Container'
import { ThemeVariants } from 'fitify-ui-landing/src/theme/index'
import { useTranslation } from 'next-i18next'
import React from 'react'
import { useTheme } from 'styled-components'

import {
  StyledHowItWorksIdContainer,
  StyledHowItWorksItem,
  StyledHowItWorksList,
  StyledHowItWorksSection,
} from './HowItWorks.Styled'

const HowItWorks = ({
  title,
  items,
  columns = 3,
  variant,
  children,
}: {
  title: string
  items: DataTypes.HowItWorksItem[]
  variant?: PageTypes.PageVariant
  columns?: number
  children?: React.ReactNode
}) => {
  const { t } = useTranslation()

  const theme = useTheme()

  return (
    <StyledHowItWorksSection>
      <StyledHowItWorksIdContainer id={ELEMENT_IDS.howItWorks} />
      <ContentRevealAnimation>
        <Container>
          <Text
            as={'h2'}
            variant={
              theme.variant === ThemeVariants.DIGITAL
                ? 'business-title'
                : 'condensed-title'
            }
          >
            {title}
          </Text>
          <StyledHowItWorksList columns={columns}>
            {items.map((item, index: number) => {
              return (
                <StyledHowItWorksItem key={index} $iconColor={item.iconColor}>
                  {item.icon}
                  <Text as={'h3'} variant={'h2'}>
                    {t(item.title)}
                  </Text>
                  <Text
                    variant={
                      variant === PageTypes.PageVariant.BUSINESS
                        ? 'how-it-works-content'
                        : 'feature-content'
                    }
                    color={'Gray700'}
                  >
                    {t(item.content)}
                  </Text>
                </StyledHowItWorksItem>
              )
            })}

            {children}
          </StyledHowItWorksList>
        </Container>
      </ContentRevealAnimation>
    </StyledHowItWorksSection>
  )
}

export default HowItWorks
