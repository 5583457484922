import {
  BMeetingIcon,
  InterviewIcon,
  JumpRopeIcon,
  Multiple11Icon,
  PersonalTrainerIcon,
  Pin4Icon,
  SpaceshipIcon,
  WorkoutPlanIcon,
} from 'fitify-ui/src/Icon/fill'
import { DataTypes } from 'fitify-ui-landing/src/@types'

const howItWorksData: DataTypes.HowItWorksItem[] = [
  {
    icon: <PersonalTrainerIcon />,
    iconColor: 'Green400',
    title: 'hc_landing_get_matched_title',
    content: 'hc_landing_get_matched_text',
  },
  {
    icon: <InterviewIcon />,
    iconColor: 'Green400',
    title: 'hc_landing_connect_with_coach_title',
    content: 'hc_landing_connect_with_coach_text',
  },
  {
    icon: <WorkoutPlanIcon />,
    iconColor: 'Green400',
    title: 'hc_landing_get_personal_training_title',
    content: 'hc_landing_get_personal_training_text',
  },
  {
    icon: <JumpRopeIcon />,
    iconColor: 'Green400',
    title: 'hc_landing_exercise_when_it_works_title',
    content: 'hc_landing_exercise_when_it_works_text',
  },
  {
    icon: <BMeetingIcon />,
    iconColor: 'Green400',
    title: 'hc_landing_stay_on_track_title',
    content: 'hc_landing_stay_on_track_text',
  },
]

const businessHowItWorksData: DataTypes.HowItWorksItem[] = [
  {
    icon: <Pin4Icon />,
    iconColor: 'Blue400',
    title: 'hc_landing_work_how_it_works_block_1_title',
    content: 'hc_landing_work_how_it_works_block_1_subtitle',
  },
  {
    icon: <WorkoutPlanIcon />,
    iconColor: 'Blue400',
    title: 'hc_landing_work_how_it_works_block_2_title',
    content: 'hc_landing_work_how_it_works_block_2_subtitle',
  },
  {
    icon: <Multiple11Icon />,
    iconColor: 'Blue400',
    title: 'hc_landing_work_how_it_works_block_3_title',
    content: 'hc_landing_work_how_it_works_block_3_subtitle',
  },
  {
    icon: <SpaceshipIcon />,
    iconColor: 'Blue400',
    title: 'hc_landing_work_how_it_works_block_4_title',
    content: 'hc_landing_work_how_it_works_block_4_subtitle',
  },
]

export { howItWorksData, businessHowItWorksData }
